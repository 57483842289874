// @flow strict
import React from "react";
import Author from "./Author";
import Contacts from "./Contacts";
import Copyright from "./Copyright";
import Terms from "./Terms";
import Menu from "./Menu";
import PinnedPosts from "./PinnedPosts";
import styles from "./Sidebar.module.scss";
import { useSiteMetadata, usePinnedPosts } from "../../hooks";

type Props = {
  isIndex?: boolean,
};

const Sidebar = ({ isIndex }: Props) => {
  const { author, copyright, menu, terms } = useSiteMetadata();
  const pinnedPosts = usePinnedPosts();
  return (
    <div className={styles["sidebar"]}>
      <div className={styles["sidebar__inner"]}>
        <Author author={author} isIndex={isIndex} />
        <hr className={styles["sidebar__hr"]} />
        <Menu menu={menu} />
        <hr className={styles["sidebar__hr"]} />
        <PinnedPosts edges={pinnedPosts} />
        <hr className={styles["sidebar__hr"]} />
        <Contacts contacts={author.contacts} />
        <Copyright copyright={copyright} />
        <Terms terms={terms.text} url={terms.url} />
      </div>
    </div>
  );
};

export default Sidebar;
