// @flow strict
import React from "react";
import { Link } from "gatsby";
import type { Edges } from '../../types';
import styles from "./PinnedPosts.module.scss";

type Props = {
  edges: Edges
};

const PinnedPosts = ({ edges }: Props) => (
  <section>
    <div className={styles["pinned-post-header"]}>
      <h3>Pinned Posts</h3>
    </div>
    {edges.map((post) => (
      <article
        key={post.node.fields.slug}
        className={styles["pinned-post-entry"]}
      >
        <header className={styles["entry-header"]}>
          <h4>
            <Link to={post.node.fields.slug}>
              {post.node.frontmatter.title}
            </Link>
          </h4>
        </header>
        <footer className={styles["entry-footer"]}>
          {new Date(post.node.frontmatter.date).toLocaleDateString(
            {},
            {
              timeZone: "UTC",
              month: "short",
              day: "2-digit",
              year: "numeric",
            }
          )}
        </footer>
      </article>
    ))}
  </section>
);

export default PinnedPosts;
